import React, { useState, useEffect } from 'react';
import LoadingSpinner from '../core/layout/LoadingSpinner/LoadingSpinner';
import { getReviewItemsOfUser, getStudentAssignmentProgress } from '../../utils/requests';
import { AssignmentProgress, ReviewByItem } from '../../types/types';
import { useParams } from 'react-router-dom';
import TabList from '../core/layout/TabList/TabList';
import StudentReviewResults from './StudentReviewResults';
import Button from '../core/button/Button/Button';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { getPhaseStatus } from '../../utils/functions';
import StudentReflectionResults from './StudentReflectionResults';
import StudentEvalResults from './StudentEvalResults';
import StudentGroupFormationResults from './StudentGroupFormationResults';
import StudentSubmissionResultsOld from './StudentSubmissionResultsOld';

function StudentResultsPage(): JSX.Element {
  const { assignmentId } = useParams() as { assignmentId: string };

  const [assignmentProgress, setAssignmentProgress] = useState<AssignmentProgress | null>(null);
  const [reviewItems, setReviewItems] = useState<ReviewByItem[] | null>(null);

  const { assignment } = assignmentProgress || {};
  const user = useSelector((state: RootState) => state.user);

  useEffect(() => {
    getStudentAssignmentProgress(assignmentId, setAssignmentProgress);
    getReviewItemsOfUser(assignmentId, user.userId, setReviewItems);
  }, [assignmentId, user]);

  if (assignmentProgress && reviewItems && assignment) {
    const phaseStatus = getPhaseStatus(assignment);
    return (
      <div className="page results-row">
        {assignmentProgress.status.hasResult &&
        !(assignment.hideGradeResults && assignment.hideCommentResults && assignment.hideRatingResults) ? (
          <TabList
            label="Results Menu"
            tabs={
              <>
                {phaseStatus.submission ? (
                  <TabList.Tab id="submission" controls="submission-tab">
                    Submission
                  </TabList.Tab>
                ) : null}
                {phaseStatus.review ? (
                  <TabList.Tab id="review" controls="review-tab">
                    Review
                  </TabList.Tab>
                ) : null}
                {assignment.peerEvaluationEnabled ? (
                  <TabList.Tab id="evaluations" controls="evaluations-tab">
                    Evaluations
                  </TabList.Tab>
                ) : null}
                {assignment.reflectionEnabled ? (
                  <TabList.Tab id="reflection" controls="reflection-tab">
                    Reflection
                  </TabList.Tab>
                ) : null}
                {phaseStatus.groupFormation ? (
                  <TabList.Tab id="group-formation" controls="group-formation-tab">
                    Group Formation
                  </TabList.Tab>
                ) : null}
                {assignment.studentExportEnabled && assignmentProgress.exportUrl ? (
                  <TabList.Tab id="exports" controls="export-tab">
                    Export Results
                  </TabList.Tab>
                ) : null}
              </>
            }
          >
            {phaseStatus.submission ? (
              <TabList.TabPanel id="submission-tab" labeledBy="submission">
                <StudentSubmissionResultsOld assignmentProgress={assignmentProgress} />
              </TabList.TabPanel>
            ) : null}

            {phaseStatus.review ? (
              <TabList.TabPanel id="review-tab" labeledBy="review">
                <StudentReviewResults assignmentProgress={assignmentProgress} reviewItems={reviewItems} />
              </TabList.TabPanel>
            ) : null}

            {assignment.peerEvaluationEnabled ? (
              <TabList.TabPanel id="evaluations-tab" labeledBy="evaluations">
                <StudentEvalResults assignmentProgress={assignmentProgress} />
              </TabList.TabPanel>
            ) : null}

            {assignment.reflectionEnabled ? (
              <TabList.TabPanel id="reflection-tab" labeledBy="reflection">
                <StudentReflectionResults userId={user.userId} />
              </TabList.TabPanel>
            ) : null}

            {phaseStatus.groupFormation ? (
              <TabList.TabPanel id="group-formation-tab" labeledBy="group-formation">
                <StudentGroupFormationResults assignmentProgress={assignmentProgress} />
              </TabList.TabPanel>
            ) : null}
            {assignmentProgress.exportUrl ? (
              <TabList.TabPanel id="export-tab" labeledBy="exports">
                <div id="export-results-container" className="fadeIn">
                  <div id="export-results-panel" className="panel-white">
                    <h1>Export Results</h1>
                    <p>
                      Here you can download a zip file containing your submission as well as all review, feedback, and
                      reflection data for this assignment.
                    </p>
                    <div className="action-section">
                      <Button
                        variant="rad sm low"
                        href={assignmentProgress.exportUrl}
                        tooltip={'Export your assignment data.'}
                        download={''}
                      >
                        Export Results
                      </Button>
                    </div>
                  </div>
                </div>
              </TabList.TabPanel>
            ) : null}
          </TabList>
        ) : (
          <div id="no-results-container" className="fadeIn">
            <div id="grades-unavailable-panel" className="panel-white">
              <h1>Results Unavailable</h1>
              <p>Results will be available once the assignment is fully completed by your class.</p>
            </div>
          </div>
        )}
      </div>
    );
  }
  return <LoadingSpinner />;
}

export default StudentResultsPage;
