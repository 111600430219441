import React, { useState, useMemo, useCallback, useEffect, useRef, ChangeEvent } from 'react';
import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  ColumnDef,
  Row,
  PaginationState,
  flexRender,
  SortingState,
  Header,
  Cell,
} from '@tanstack/react-table';
import { CSVLink } from 'react-csv';
import Pagination from '../../layout/Pagination/Pagination';
import { appendClass, handleKeySelect, outsideClick, storageAvailable } from '../../../../utils/functions';
import SearchBar from '../../input/SearchBar/SearchBar';
import { KEY_TABLE_PAGE_SIZE } from '../../../../utils/constants';
import Icon from '../Icon';
import _ from 'lodash';
import JumpButton from '../../button/JumpButton';
import moment from 'moment';

function Table<T extends Record<string, unknown>>({
  children,
  colGroups,
  columns,
  data,
  defaultPageSize,
  getRowProps = () => ({}),
  headerIconMap,
  headingLevel = 2,
  hideDownload = false,
  hideTitle = false,
  hidePagination = false,
  hideSearch = false,
  id,
  informOfRow,
  lockSortColumnId,
  mergeLockedColCells = false,
  noWrapper = false,
  onAdd,
  selectedRowContent,
  setToggleHiddensByGroup,
  sortBy,
  title,
  date,
}: {
  children?: React.ReactNode;
  colGroups?: string[][];
  columns: ColumnDef<T, string>[];
  data: Array<T>;
  defaultPageSize?: number;
  getRowProps?: (row: Row<T>) => { style?: React.CSSProperties };
  headerIconMap?: { [index: string]: string };
  headingLevel?: number;
  hideDownload?: boolean;
  hideTitle?: boolean;
  hidePagination?: boolean;
  hideSearch?: boolean;
  id?: string;
  informOfRow?: (row: Row<T>) => void;
  lockSortColumnId?: string;
  mergeLockedColCells?: boolean;
  noWrapper?: boolean;
  onAdd?: () => void;
  selectedRowContent?: JSX.Element | null;
  setToggleHiddensByGroup?: React.Dispatch<React.SetStateAction<((value: boolean) => void)[][]>>;
  sortBy?: string;
  title: string;
  date?: boolean;
}): JSX.Element {
  const [columnVisibility, setColumnVisibility] = useState({});
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState<SortingState>(sortBy ? [{ id: sortBy, desc: false }] : []);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: parseInt(
      storageAvailable('localStorage') ? window.localStorage.getItem(KEY_TABLE_PAGE_SIZE) || '10' : '10',
    ),
  });

  const {
    getAllColumns,
    getHeaderGroups,
    getPageCount,
    getRowModel,
    nextPage,
    previousPage,
    setPageIndex,
    setPageSize,
  } = useReactTable({
    data,
    columns,
    state: {
      columnVisibility,
      globalFilter,
      pagination,
      sorting,
    },
    onColumnVisibilityChange: setColumnVisibility,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  const uniqueId = useRef(_.uniqueId());
  const tableEl = useRef<HTMLTableElement>(null);
  const theadEl = useRef<HTMLTableSectionElement>(null);
  const tbodyEl = useRef<HTMLTableSectionElement>(null);

  const [selectedRow, setSelectedRow] = useState(-1);
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);

  const colNum = getAllColumns().length;

  const headersForCsvExport = useMemo(() => {
    const headers: string[] = [];
    if (columns && columnVisibility && sorting && pagination)
      getAllColumns()
        .filter((col) => col.getIsVisible())
        .forEach((col) => {
          headers.push(col.columnDef.header?.toString() ?? col.id);
        });
    return headers;
  }, [columnVisibility, columns, getAllColumns, pagination, sorting]);

  const dataForCsvExport = useMemo(() => {
    const compiledData: (string | number)[][] = [[]];
    if (data && columnVisibility && sorting && pagination)
      getRowModel().rows.forEach((row) => {
        const newRow: (string | number)[] = [];
        row.getVisibleCells().forEach((cell) => newRow.push(cell.getValue() as string | number));
        compiledData.push(newRow);
      });
    return compiledData;
  }, [columnVisibility, data, getRowModel, pagination, sorting]);

  const handleRowSelect = useCallback(
    (e: React.MouseEvent, row: Row<T>) => {
      // informOfRow must be implemented to have selection functionality
      if (informOfRow && theadEl.current) {
        let elem = e.target as HTMLElement;
        if (elem.tagName === 'TD' && elem.parentElement) elem = elem.parentElement;
        if (elem.tagName === 'TR') {
          const numHeadRows = theadEl.current.childElementCount;
          let newSelectedRow = (elem as HTMLTableRowElement).rowIndex - numHeadRows;
          if (selectedRow >= 0 && selectedRow < newSelectedRow) newSelectedRow--;
          setSelectedRow(newSelectedRow);
          informOfRow(row);
        }
      }
    },
    [informOfRow, selectedRow],
  );

  /**
   * Create event listener for deselecting from the selected row
   */
  useEffect(() => {
    // informOfRow must be implemented to have selection functionality
    if (informOfRow) {
      const handleMouseDown = (e: MouseEvent) => {
        if (tbodyEl.current && outsideClick(e, [tbodyEl.current])) {
          setSelectedRow(-1);
        }
      };
      window.addEventListener('mousedown', handleMouseDown);
      return () => window.removeEventListener('mousedown', handleMouseDown);
    }
  }, [informOfRow]);

  useEffect(() => {
    if (colGroups) {
      const toggleHiddens: (() => void)[][] = [];
      for (let i = 0; i < colGroups.length; i++) {
        toggleHiddens.push([]);
      }
      getAllColumns().forEach((col) => {
        colGroups.forEach((group, i) => {
          if (group.includes(col.id)) {
            toggleHiddens[i].push(col.toggleVisibility);
          }
        });
      });
      if (setToggleHiddensByGroup) setToggleHiddensByGroup(toggleHiddens);
    }
  }, [getAllColumns, colGroups, setToggleHiddensByGroup]);

  useEffect(() => {
    setSelectedRow(-1);
  }, []);

  useEffect(() => {
    if (defaultPageSize) setPageSize(defaultPageSize);
  }, [defaultPageSize, setPageSize]);

  useEffect(() => {
    if (sortBy) setSorting([{ desc: false, id: sortBy }]);
    else setSorting([]);
  }, [sortBy]);

  const triggerSortHandler = (header: Header<T, unknown>) => {
    const { column } = header;
    if (lockSortColumnId === undefined) {
      setSorting((prevSorting) => {
        let newSorting: SortingState = [];
        if (prevSorting.length === 1) {
          const currSortState = sorting[0];
          const isNewSort = currSortState.id !== column.id;
          const desc = !isNewSort;
          const id = isNewSort || !currSortState.desc ? column.id : sortBy;
          if (id) newSorting.push({ desc, id });
        }
        return newSorting;
      });
    } else {
      setSorting((prevSorting) => {
        const index = prevSorting.findIndex((sortState) => sortState.id === column.id);
        let newSorting: SortingState = [];
        if (index > -1) {
          newSorting = _.clone(prevSorting);
          const isDesc = newSorting[index].desc;
          if (column.id === lockSortColumnId) {
            newSorting[index].desc = !isDesc;
          } else {
            if (isDesc) {
              newSorting.splice(index, 1);
            } else {
              newSorting[index].desc = true;
            }
          }
        } else {
          const lockSortState = prevSorting.find((sortState) => sortState.id === lockSortColumnId);
          if (lockSortState) newSorting.push(lockSortState);
          newSorting.push({ desc: false, id: column.id });
        }
        return newSorting;
      });
    }
  };

  const getCellClassName = (cell: Cell<T, unknown>, cellIndex: number, rows: Row<T>[], rowIndex: number) => {
    const mergeCellDown =
      mergeLockedColCells &&
      cell.column.id === lockSortColumnId &&
      rowIndex + 1 < rows.length &&
      rows[rowIndex + 1].getVisibleCells()[cellIndex].getValue() === cell.getValue();
    const mergeCellUp =
      mergeLockedColCells &&
      cell.column.id === lockSortColumnId &&
      rowIndex - 1 >= 0 &&
      rows[rowIndex - 1].getVisibleCells()[cellIndex].getValue() === cell.getValue();
    let className = cell.column.columnDef.meta?.className ?? '';
    if (mergeCellDown) className = appendClass(className, 'merge-cell-down');
    if (mergeCellUp) className = appendClass(className, 'merge-cell-up');
    return className;
  };

  const handleStartDateChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setStartDate(moment(event.target.value).format('YYYY-MM-DD h:mm:ss'));
  }, []);

  const handleEndDateChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      let endDateForSearch = moment(event.target.value).format('YYYY-MM-DD h:mm:ss');
      setEndDate(moment(event.target.value).format('YYYY-MM-DD h:mm:ss'));
      const dates = `date ${startDate} ${endDateForSearch}`;
    },
    [startDate],
  );

  const HeadingTag = `h${headingLevel}` as keyof JSX.IntrinsicElements;

  return (
    <div className={`peer-table-wrapper ${noWrapper ? '' : 'panel-sm panel-white'}`} id={id}>
      <HeadingTag
        id={`table-heading-${uniqueId.current}`}
        className={`title ${noWrapper || hideTitle ? 'sr-only' : ''}`}
      >
        {title}
      </HeadingTag>
      {children}
      <div className="table-ctrls-top">
        {date ? (
          <div className="date">
            <label htmlFor="startDate">Start Date</label>
            <input
              id="startDate"
              type="date"
              value={startDate ? moment(startDate).format('YYYY-MM-DD') : ''}
              onChange={handleStartDateChange}
            />

            <label htmlFor="endDateText">End Date</label>
            <input
              id="endDate"
              type="date"
              value={endDate ? moment(endDate).format('YYYY-MM-DD') : ''}
              onChange={handleEndDateChange}
            />
          </div>
        ) : null}
        {!hideSearch ? (
          <GlobalFilter globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} resultsLength={data.length} />
        ) : null}
        {defaultPageSize === undefined ? (
          <span className="entries-select-wrapper">
            <select
              aria-label="Table Page Size"
              value={pagination.pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
                if (storageAvailable('localStorage'))
                  window.localStorage.setItem(KEY_TABLE_PAGE_SIZE, e.target.value + '');
              }}
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </select>{' '}
            of {data.length} results
          </span>
        ) : null}
      </div>

      <JumpButton
        invisible
        id={`pre-table-btn-${uniqueId.current}`}
        targetId={`post-table-btn-${uniqueId.current}`}
        type="focus"
      >
        Skip to after table
      </JumpButton>

      <div className="table-scrollable-wrapper">
        <table ref={tableEl} className="peer-table" aria-describedby={`table-heading-${uniqueId.current}`}>
          <thead ref={theadEl}>
            {getHeaderGroups().map((headerGroup, i, headerGroups) => {
              const lastHeaderRow = headerGroups.length === 1 || i === headerGroups.length - 1;
              return (
                <tr key={headerGroup.id} aria-hidden={!lastHeaderRow}>
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      tabIndex={!header.isPlaceholder ? 0 : -1}
                      onClick={() => triggerSortHandler(header)}
                      {...header.column.columnDef.meta}
                      style={{
                        ...header.column.columnDef.meta?.style,
                        cursor: lastHeaderRow ? 'pointer' : undefined,
                      }}
                    >
                      {header.isPlaceholder ? null : (
                        <div className="table-header-wrapper">
                          {header.headerGroup && header.column.parent ? (
                            <span className="sr-only">{header.column.parent.columnDef.header?.toString()}</span>
                          ) : null}
                          <span className="header-title">
                            {headerIconMap ? <Icon className="header-icon" code={headerIconMap[header.id]} /> : null}
                            {flexRender(header.column.columnDef.header, header.getContext())}
                          </span>
                          <span
                            className={`sort-arrows ${
                              header.column.getIsSorted() ? `${header.column.getIsSorted() ?? 'asc'}ending` : 'sr-only'
                            }`}
                            role="button"
                            aria-label={`Sort by ${
                              header.column.parent ? `${header.column.parent.columnDef.header?.toString()} ` : ''
                            }${header.column.columnDef.header?.toString()} ${
                              header.column.getIsSorted() === false
                                ? 'Sort ascending'
                                : header.column.getIsSorted() === 'asc'
                                ? 'Sort descending'
                                : 'Unsort'
                            }`}
                            onKeyDown={(e) => handleKeySelect(e, () => triggerSortHandler(header))}
                            tabIndex={lastHeaderRow ? 0 : -1}
                          >
                            <Icon code="switch_left" ariaHidden />
                          </span>
                        </div>
                      )}
                    </th>
                  ))}
                </tr>
              );
            })}
          </thead>
          <tbody ref={tbodyEl}>
            {getRowModel().rows.length > 0 ? (
              getRowModel().rows.map((row, i, rows) => {
                const newRow = (
                  <tr
                    key={row.id}
                    className={i === selectedRow ? 'selected-row' : undefined}
                    onClick={(e: React.MouseEvent) => handleRowSelect(e, row)}
                    {...getRowProps(row)}
                  >
                    {row.getVisibleCells().map((cell, cellIndex) => (
                      <td
                        key={cell.id}
                        aria-label={`${
                          cell.column.parent?.columnDef.header?.toString() ?? ''
                        } ${cell.column.columnDef.header?.toString()}: ${cell.getContext().getValue()};`}
                        {...cell.column.columnDef.meta}
                        className={getCellClassName(cell, cellIndex, rows, i)}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    ))}
                    {informOfRow ? (
                      <td
                        className="sr-only sr-show-on-focus"
                        role="button"
                        tabIndex={0}
                        onKeyDown={(e) => handleKeySelect(e, () => informOfRow(row))}
                      >
                        Select Row {i + 1} ({row.getVisibleCells()[0].column.columnDef.header?.toString()}:{' '}
                        <>{row.getVisibleCells()[0].getValue()}</>)
                      </td>
                    ) : null}
                  </tr>
                );
                if (i === selectedRow)
                  return [
                    newRow,
                    <tr key={`expanded-row-${i}`} className="row-more-content">
                      <td colSpan={colNum}>{selectedRowContent}</td>
                    </tr>,
                  ];
                return newRow;
              })
            ) : (
              <tr>
                <td align="center" colSpan={99}>
                  No results available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <JumpButton
        invisible
        id={`post-table-btn-${uniqueId.current}`}
        targetId={`pre-table-btn-${uniqueId.current}`}
        type="focus"
      >
        Skip to before table
      </JumpButton>

      {!hidePagination ? (
        <div className="pagination-wrapper">
          <Pagination
            currPage={pagination.pageIndex}
            pageCount={getPageCount()}
            nextPage={nextPage}
            prevPage={previousPage}
            goToPage={setPageIndex}
            uniqueLabel={title ? `${title} Table` : undefined}
          />
        </div>
      ) : null}

      {onAdd ? (
        <div className="table-ctrls-bottom-left">
          <button className="circ-btn" onClick={onAdd}>
            <Icon code="add" />
          </button>
        </div>
      ) : (
        ''
      )}
      {!hideDownload ? (
        <div className="table-ctrls-bottom-right">
          <CSVLink
            headers={headersForCsvExport}
            data={dataForCsvExport}
            filename={`${(title ?? 'table')
              .toLocaleLowerCase()
              .replace(/[^a-zA-Z ]/g, '')
              .replace(/\s+/g, '-')}.csv`}
            className="circ-btn"
            target="_blank"
          >
            <Icon code="download" />
          </CSVLink>
        </div>
      ) : null}
    </div>
  );
}

function GlobalFilter({
  globalFilter,
  resultsLength,
  setGlobalFilter,
}: {
  globalFilter: string;
  resultsLength: number;
  setGlobalFilter: (filterValue: string) => void;
}): JSX.Element {
  const [value, setValue] = React.useState(globalFilter || '');
  const onChange = useMemo(
    () => _.debounce((value) => setGlobalFilter(value || undefined), 200, { maxWait: 1000 }),
    [setGlobalFilter],
  );

  return (
    <SearchBar
      value={value}
      setValue={(value) => {
        setValue(value);
        onChange(value);
      }}
      resultsLength={resultsLength}
    />
  );
}

export const getCellWithUnit = (value: number | string, unit: string, indicators?: JSX.Element[]): JSX.Element => {
  return (
    <>
      <span>{value}</span>
      <span className="data-unit">{unit}</span>
      {indicators}
    </>
  );
};

export const getCellWithIndicators = (value: number | string, indicators?: JSX.Element[]): JSX.Element => {
  return (
    <>
      <span>{value}</span>
      {indicators}
    </>
  );
};

export default Table;
