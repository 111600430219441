import React, { useMemo, useCallback } from 'react';
import { User } from '../../types/types';
import { schoolAdminGetUsers } from '../../utils/requests';
import { KEY_SELECTED_USER } from '../../utils/constants';
import moment from 'moment';
import { storageAvailable, timeDiff } from '../../utils/functions';
import QueryTable, { Column } from '../core/display/QueryTable/QueryTable';
import DashboardStatsBar from '../core/layout/DashboardStatsBar/DashboardStatsBar';
import { useNavigate } from 'react-router-dom';

function UserLibrary(): JSX.Element {
  const navigate = useNavigate();

  const handleStudentSelect = useCallback(
    (user: User) => {
      if (storageAvailable('sessionStorage')) window.sessionStorage.setItem(KEY_SELECTED_USER, JSON.stringify(user));
      navigate(`/user/${user.userId}`);
    },
    [navigate],
  );

  const tableColumns = useMemo<Column<User>[]>(
    () => [
      {
        Header: 'Name',
        accessor: 'lastName',
        Cell: (user: User) => user.sortableName,
        className: 'bold',
        customSort: 'lastName,firstName',
      },
      {
        Header: 'Institution',
        accessor: 'institutionId',
        className: 'role',
        Cell: (user: User) => user.institutionId,
      },
      { Header: 'Email', accessor: 'email' },
      { Header: 'Role', accessor: 'role', className: 'role', Cell: (user: User) => user.role.toLocaleLowerCase() },
      {
        Header: 'LTI Connected',
        accessor: 'ltiUser',
        className: 'role',
        Cell: (user: User) => (user.ltiUser === true ? 'true' : 'false'),
      },

      { Header: 'Joined', accessor: 'createdAt', Cell: (user: User) => moment(user.createdAt).format('MMM D, YYYY') },
      {
        Header: 'Last Active',
        accessor: 'lastLoginTime',
        Cell: (user: User) => (user.lastLoginTime ? `${timeDiff(user.lastLoginTime ?? '')} ago` : 'Never'),
      },
    ],
    [],
  );

  return (
    <div className="page home-page" id="user-libary">
      <DashboardStatsBar type="USER" />
      <h1 className="sr-only">Users</h1>
      <QueryTable
        title="Users"
        columns={tableColumns}
        queryRequest={schoolAdminGetUsers}
        onRowSelect={handleStudentSelect}
        role={true}
        institution={true}
        date={true}
        zeroCourse={true}
        classNames={{
          ctrlsClassName: 'ctrls-row',
          tableClassName: 'home-body',
        }}
      />
    </div>
  );
}

export default UserLibrary;
